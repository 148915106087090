<template>
  <div>
    <BlockUI
      v-show="$store.state.showBlock"
      message="Vui lòng chờ trong giây lát"
    >
      <sweetalert-icon
        icon="loading"
        size="12"
      />
    </BlockUI>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          Danh sách thành viên
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData.user">
      <!-- First Row -->
      <user-view-user-info-card :user-data="userData" />
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BAlert, BLink,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import userStoreModule from '../../userStoreModule'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'

export default {
  components: {
    BAlert,
    BLink,

    // Local Components
    UserViewUserInfoCard,
  },
  setup() {
    const blankUserData = {
      id: '',
      hoten: '',
      username: '',
      mat_khau: '',
      dien_thoai: '',
      ngay_sinh: '',
      email: '',
      khu_vuc_id: '',
      dai_ly_id: '',
      dai_ly: 1,
      khach_hang: 0,
    }
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const currentUser = getUserData()

    store.state.showBlock = true
    store.dispatch('app-user/xemChiTiet', {
      user: router.currentRoute.params.id,
      auth: currentUser.auth_key,
      uid: currentUser.id,
      type: 'Đại lý',
      khach_hang: router.currentRoute.params.id,
    })
      .then(response => {
        userData.value = response.data
        store.state.showBlock = false
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
        store.state.showBlock = false
      })

    return {
      userData,
    }
  },
}
</script>

<style lang="scss">
.rich-media-node {
  width: 200px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #f7c616;
  border-radius: 4px;
  text-align: center;
}
</style>
