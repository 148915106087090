<template>
  <div>
    <b-card>
      <b-row>

        <!-- User Info: Left col -->
        <b-col
          cols="21"
          xl="6"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :text="avatarText(userData.user.hoten)"
              variant="light-primary"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-1">
                  <b-badge
                    variant="info"
                    class="badge-glow"
                  >
                    {{ userData.user.ma_dai_ly }}
                  </b-badge>
                </h4>
                <h4 class="mb-0">
                  {{ userData.user.hoten }}
                </h4>
                <span class="card-text">{{ userData.user.email }}</span>
              </div>
            </div>
          </div>

          <!-- User Stats -->
          <div class="d-flex align-items-center mt-2">

            <div class="d-flex align-items-center">
              <b-avatar
                variant="light-info"
                rounded
              >
                <feather-icon
                  icon="Share2Icon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  {{ userData.user.so_lan_xem_con_lai }}
                </h5>
                <small>Bài còn lại</small>
              </div>
            </div>
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="6"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Tên đăng nhập</span>
              </th>
              <td class="pb-50">
                {{ userData.user.username }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">SL Khách</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.so_luong_khach }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">SL Đại lý</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.so_luong_dai_ly }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="PhoneIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Điện thoại</span>
              </th>
              <td>
                {{ userData.user.dien_thoai }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>

    <b-tabs>
      <b-tab title="Danh sách đại lý">
        <b-card no-body>
          <b-card-body>
            <b-card-title>Danh sách đại lý</b-card-title>
            <b-table
              ref="refDaiLyListTable"
              class="position-relative"
              :items="userData.dai_ly"
              responsive
              :fields="tableColumnsDaiLy"
              primary-key="id"
              show-empty
              empty-text="Không có dữ liệu đại lý tuyến dưới"
            >
              <template #cell(ma_dai_ly)="data">
                <b-media vertical-align="center">
                  <b-link
                    :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
                    class="font-weight-bold d-block text-nowrap text-primary"
                  >
                    {{ data.item.hoten }}
                  </b-link>
                  <small class="text-muted">@{{ data.item.username }}</small>
                </b-media>
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-tab>
      <b-tab
        title="Danh sách khách hàng"
      >
        <b-card no-body>
          <b-card-body>
            <b-card-title>Danh sách khách hàng</b-card-title>
            <b-table
              ref="refDaiLyListTable"
              class="position-relative"
              :items="userData.khach_hang"
              responsive
              :fields="tableColumnsKhachHang"
              primary-key="id"
              show-empty
              empty-text="Không có dữ liệu khách hàng"
            />
          </b-card-body>
        </b-card>

      </b-tab>
      <b-tab
        title="Lịch sử giao dịch"
        active
      >
        <b-card no-body>
          <b-card-body>
            <b-card-title>Lịch sử giao dịch</b-card-title>
            <b-table
              ref="refDaiLyListTable"
              class="position-relative"
              :items="userData.giao_dich"
              responsive
              :fields="tableColumnsGiaoDich"
              primary-key="id"
              show-empty
              empty-text="Không có dữ liệu giao dịch"
            >
              <template #cell(so_luot_xem)="data">
                <b-badge
                  :variant="viewSoLuotXem(data.item).class"
                  class="badge-glow"
                >
                  {{ viewSoLuotXem(data.item).soLuotXem }}
                </b-badge>
              </template>

              <template #cell(ngay_giao_dich)="data">
                <strong>Ngày thực hiện: </strong>{{ data.item.ngay_giao_dich }}<br>
                <strong>Ngày giờ hệ thống: </strong>{{ data.item.created }}
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import {
  BCard, BAvatar, BRow, BCol, BBadge, BCardBody, BCardTitle,
  BTable, BLink, BMedia, BTabs, BTab,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BTab,
    BTabs,
    BMedia,
    BLink,
    BCardBody,
    BCardTitle,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BTable,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // 'id', 'username', 'email', 'hoten', 'dien_thoai', 'ma_dai_ly'
      tableColumnsDaiLy: [
        { key: 'ma_dai_ly', label: 'Mã đại lý' },
        { key: 'hoten', label: 'Họ tên' },
        { key: 'dien_thoai', label: 'Điện thoại' },
        { key: 'email', label: 'Email' },

        {
          key: 'so_luong_khach', sortable: true, label: 'Số bài', thStyle: { width: '10%' },
        },
        {
          key: 'so_luong_dai_ly', sortable: true, label: 'Đ/Lý', thStyle: { width: '10%' },
        },
        {
          key: 'so_lan_xem_con_lai', sortable: true, label: 'Còn lại', thStyle: { width: '10%' },
        },
      ],

      tableColumnsKhachHang: [
        { key: 'hoten', label: 'Họ tên' },
        { key: 'dien_thoai', label: 'Điện thoại' },
        { key: 'ngay_sinh', label: 'Ngày sinh' },
      ],

      tableColumnsGiaoDich: [
        { key: 'nguoi_tao', label: 'Người tạo' },
        { key: 'loai_giao_dich', label: 'Loại giao dịch' },
        { key: 'ngay_giao_dich', label: 'Ngày giao dịch' },
        { key: 'so_luot_xem', label: 'Số lượng' },
        { key: 'khach_hang', label: 'Người liên quan' },
      ],
    }
  },
  setup() {
    const viewSoLuotXem = item => {
      if (item.loai_giao_dich === 'Mua bài') {
        return {
          class: 'success',
          soLuotXem: `+ ${item.so_luot_xem}`,
        }
      }
      return {
        class: 'danger',
        soLuotXem: `- ${item.so_luot_xem}`,
      }
    }
    return {
      viewSoLuotXem,
      avatarText,
    }
  },
}
</script>

<style>

</style>
